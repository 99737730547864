import logo from './assets/img/logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header mt24">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>
          hello, my name is rinne.
        </h2>
      </header>
    </div>
  );
}

export default App;
